/**
 * @flow
 * @relayHash 89f3d815547959a664ce338ae45865c7
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type Simulate_data$ref = any;
export type meetingsSimulateQueryVariables = {|
  id: string
|};
export type meetingsSimulateQueryResponse = {|
  +meeting: ?{|
    +$fragmentRefs: Simulate_data$ref
  |}
|};
export type meetingsSimulateQuery = {|
  variables: meetingsSimulateQueryVariables,
  response: meetingsSimulateQueryResponse,
|};
*/


/*
query meetingsSimulateQuery(
  $id: ID!
) {
  meeting: node(id: $id) {
    __typename
    ...Simulate_data
    id
  }
}

fragment Simulate_data on TestMeeting {
  id
  startTime(format: "hh:mm A")
  duration
  token
  createdAt(format: "MMM Do, YYYY")
  advisor {
    displayName
    photoURL
    username
    id
  }
  seeker {
    displayName
    photoURL
    username
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "id",
    "type": "ID!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = [
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "displayName",
    "args": null,
    "storageKey": null
  },
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "photoURL",
    "args": null,
    "storageKey": null
  },
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "username",
    "args": null,
    "storageKey": null
  },
  (v2/*: any*/)
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "meetingsSimulateQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": "meeting",
        "name": "node",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "Simulate_data",
            "args": null
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "meetingsSimulateQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": "meeting",
        "name": "node",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "__typename",
            "args": null,
            "storageKey": null
          },
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "type": "TestMeeting",
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "startTime",
                "args": [
                  {
                    "kind": "Literal",
                    "name": "format",
                    "value": "hh:mm A"
                  }
                ],
                "storageKey": "startTime(format:\"hh:mm A\")"
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "duration",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "token",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "createdAt",
                "args": [
                  {
                    "kind": "Literal",
                    "name": "format",
                    "value": "MMM Do, YYYY"
                  }
                ],
                "storageKey": "createdAt(format:\"MMM Do, YYYY\")"
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "advisor",
                "storageKey": null,
                "args": null,
                "concreteType": "User",
                "plural": false,
                "selections": (v3/*: any*/)
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "seeker",
                "storageKey": null,
                "args": null,
                "concreteType": "User",
                "plural": false,
                "selections": (v3/*: any*/)
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "meetingsSimulateQuery",
    "id": null,
    "text": "query meetingsSimulateQuery(\n  $id: ID!\n) {\n  meeting: node(id: $id) {\n    __typename\n    ...Simulate_data\n    id\n  }\n}\n\nfragment Simulate_data on TestMeeting {\n  id\n  startTime(format: \"hh:mm A\")\n  duration\n  token\n  createdAt(format: \"MMM Do, YYYY\")\n  advisor {\n    displayName\n    photoURL\n    username\n    id\n  }\n  seeker {\n    displayName\n    photoURL\n    username\n    id\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'eee7fa0a82a88c27a5bed056d069cd83';
module.exports = node;
