/* @flow */

import React from 'react';
import { graphql } from 'relay-runtime';
import Layout from '../common/Layout';

export default [
  {
    path: ['/meetings', '/advising/meetings'],
    query: graphql`
      query meetingsQuery {
        ...Layout_data
        ...Meetings_data
      }
    `,
    components: () => [import(/* webpackChunkName: 'meetings' */ './Meetings')],
    render: ([Meetings], data) => ({
      title: 'Meetings • Prepdup',
      component: (
        <Layout data={data}>
          <Meetings data={data} />
        </Layout>
      ),
      chunks: ['meetings'],
    }),
  },
  {
    path: '/meetings/:id/simulate',
    query: graphql`
      query meetingsSimulateQuery($id: ID!) {
        meeting: node(id: $id) {
          ...Simulate_data
        }
      }
    `,
    components: () => [import(/* webpackChunkName: 'simulate' */ './Simulate')],
    render: ([Simulate], data) => ({
      title: 'Meeting (simulator) • Prepdup',
      component: <Simulate data={data.meeting} />,
      chunks: ['simulate'],
    }),
  },
  {
    path: ['/meetings/:id', '/advising/meetings/:id'],
    query: graphql`
      query meetingsMeetingQuery($id: ID!) {
        ...Layout_data
        ...Meeting_root
        meeting: node(id: $id) {
          ...Meeting_data
        }
      }
    `,
    components: () => [
      import(/* webpackChunkName: 'meeting' */ './Meeting'),
      import(/* webpackChunkName: 'firebase' */ '@firebase/app'),
      import(/* webpackChunkName: 'firebase' */ '@firebase/auth'),
      import(/* webpackChunkName: 'firebase' */ '@firebase/firestore'),
    ],
    render: ([Meeting, firebase], data) => ({
      title: 'Meeting • Prepdup',
      component: (
        <Meeting
          data={data.meeting}
          root={data}
          layout={data}
          firebase={firebase}
        />
      ),
      chunks: ['meeting'],
    }),
  },
  {
    path: ['/meetings/recordings/:id', '/advising/meetings/recordings/:id'],
    query: graphql`
      query meetingsMeetingCompositionViewQuery($id: ID!) {
        ...Layout_data
        meeting: node(id: $id) {
          ...MeetingCompositionView_data
        }
      }
    `,
    components: () => [
      import(/* webpackChunkName: 'meetingcompositionview' */ './MeetingCompositionView'),
    ],
    render: ([MeetingCompositionView], data) =>
      data.meeting
        ? {
            title: 'Meeting Composition • Prepdup',
            component: (
              <Layout data={data}>
                <MeetingCompositionView data={data.meeting} />
              </Layout>
            ),
            chunks: ['meetingcompositionview'],
          }
        : undefined,
  },
];
