/* @flow */

export const DefaultTimeZone = 'America/Los_Angeles';
export const AdvisorUriPrefix = '/advising';

export const ViewMode = {
  SEEKER: 'SEEKER',
  ADVISOR: 'ADVISOR',
  GUEST: 'GUEST',
};

export const AdvisorStatus = {
  PENDING_APPROVAL: 'Pending',
  APPROVED: 'Approved',
};

export const StaticPages = [
  '/login',
  '/about',
  '/terms',
  '/privacy',
  '/settings',
];

export const PageCount = 30;
