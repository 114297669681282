/**
 * @flow
 * @relayHash 5636b20b7be07de8dd4ae2d9e44182df
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type Login_data$ref = any;
export type userLoginQueryVariables = {||};
export type userLoginQueryResponse = {|
  +$fragmentRefs: Login_data$ref
|};
export type userLoginQuery = {|
  variables: userLoginQueryVariables,
  response: userLoginQueryResponse,
|};
*/


/*
query userLoginQuery {
  ...Login_data
}

fragment Login_data on Query {
  me {
    isAdvisor
    id
  }
}
*/

const node/*: ConcreteRequest*/ = {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "userLoginQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "FragmentSpread",
        "name": "Login_data",
        "args": null
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "userLoginQuery",
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "me",
        "storageKey": null,
        "args": null,
        "concreteType": "User",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "isAdvisor",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "userLoginQuery",
    "id": null,
    "text": "query userLoginQuery {\n  ...Login_data\n}\n\nfragment Login_data on Query {\n  me {\n    isAdvisor\n    id\n  }\n}\n",
    "metadata": {}
  }
};
// prettier-ignore
(node/*: any*/).hash = '510cd86e846579623aeae60a140d4d29';
module.exports = node;
