/**
 * @flow
 * @relayHash c44972e8049ff1b872b0769b9de302db
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type Layout_data$ref = any;
type Meetings_data$ref = any;
export type meetingsQueryVariables = {||};
export type meetingsQueryResponse = {|
  +$fragmentRefs: Layout_data$ref & Meetings_data$ref
|};
export type meetingsQuery = {|
  variables: meetingsQueryVariables,
  response: meetingsQueryResponse,
|};
*/


/*
query meetingsQuery {
  ...Layout_data
  ...Meetings_data
}

fragment Layout_data on Query {
  me {
    ...Toolbar_me
    ...AutoUpdater_user
    id
    isAdvisor
  }
  ...Toolbar_data
  ...Monitor_data
}

fragment Meetings_data on Query {
  me {
    id
    displayName
    isAdvisor
  }
  meetings(status: "UPCOMING") {
    edges {
      node {
        id
        seeker {
          username
          displayName
          photoURL
          id
        }
        advisor {
          id
          username
          displayName
          photoURL
        }
        room {
          compositionStatus
          compositionUri
          id
        }
        status
        cancelReason
        duration
        startTime
        createdAt
      }
    }
  }
}

fragment Toolbar_me on User {
  id
  username
  displayName
  photoURL
  isAdvisor
}

fragment AutoUpdater_user on User {
  id
  timeZone
}

fragment Toolbar_data on Query {
  unreadMessageCount
}

fragment Monitor_data on Query {
  unreadMessageCount
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "username",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "displayName",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "photoURL",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "meetingsQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "FragmentSpread",
        "name": "Layout_data",
        "args": null
      },
      {
        "kind": "FragmentSpread",
        "name": "Meetings_data",
        "args": null
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "meetingsQuery",
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "me",
        "storageKey": null,
        "args": null,
        "concreteType": "User",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "isAdvisor",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "timeZone",
            "args": null,
            "storageKey": null
          }
        ]
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "unreadMessageCount",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "meetings",
        "storageKey": "meetings(status:\"UPCOMING\")",
        "args": [
          {
            "kind": "Literal",
            "name": "status",
            "value": "UPCOMING"
          }
        ],
        "concreteType": "MeetingConnection",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "edges",
            "storageKey": null,
            "args": null,
            "concreteType": "MeetingEdge",
            "plural": true,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "Meeting",
                "plural": false,
                "selections": [
                  (v0/*: any*/),
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "seeker",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "User",
                    "plural": false,
                    "selections": [
                      (v1/*: any*/),
                      (v2/*: any*/),
                      (v3/*: any*/),
                      (v0/*: any*/)
                    ]
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "advisor",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "User",
                    "plural": false,
                    "selections": [
                      (v0/*: any*/),
                      (v1/*: any*/),
                      (v2/*: any*/),
                      (v3/*: any*/)
                    ]
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "room",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Room",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "compositionStatus",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "compositionUri",
                        "args": null,
                        "storageKey": null
                      },
                      (v0/*: any*/)
                    ]
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "status",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "cancelReason",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "duration",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "startTime",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "createdAt",
                    "args": null,
                    "storageKey": null
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "meetingsQuery",
    "id": null,
    "text": "query meetingsQuery {\n  ...Layout_data\n  ...Meetings_data\n}\n\nfragment Layout_data on Query {\n  me {\n    ...Toolbar_me\n    ...AutoUpdater_user\n    id\n    isAdvisor\n  }\n  ...Toolbar_data\n  ...Monitor_data\n}\n\nfragment Meetings_data on Query {\n  me {\n    id\n    displayName\n    isAdvisor\n  }\n  meetings(status: \"UPCOMING\") {\n    edges {\n      node {\n        id\n        seeker {\n          username\n          displayName\n          photoURL\n          id\n        }\n        advisor {\n          id\n          username\n          displayName\n          photoURL\n        }\n        room {\n          compositionStatus\n          compositionUri\n          id\n        }\n        status\n        cancelReason\n        duration\n        startTime\n        createdAt\n      }\n    }\n  }\n}\n\nfragment Toolbar_me on User {\n  id\n  username\n  displayName\n  photoURL\n  isAdvisor\n}\n\nfragment AutoUpdater_user on User {\n  id\n  timeZone\n}\n\nfragment Toolbar_data on Query {\n  unreadMessageCount\n}\n\nfragment Monitor_data on Query {\n  unreadMessageCount\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '8fb5ee527d2efaa06fa5701b619a89cd';
module.exports = node;
