/**
 * @flow
 * @relayHash e6230072bbe6208b31db1393e016057b
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type Login_data$ref = any;
export type userLoginEmailQueryVariables = {||};
export type userLoginEmailQueryResponse = {|
  +$fragmentRefs: Login_data$ref
|};
export type userLoginEmailQuery = {|
  variables: userLoginEmailQueryVariables,
  response: userLoginEmailQueryResponse,
|};
*/


/*
query userLoginEmailQuery {
  ...Login_data
}

fragment Login_data on Query {
  me {
    isAdvisor
    id
  }
}
*/

const node/*: ConcreteRequest*/ = {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "userLoginEmailQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "FragmentSpread",
        "name": "Login_data",
        "args": null
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "userLoginEmailQuery",
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "me",
        "storageKey": null,
        "args": null,
        "concreteType": "User",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "isAdvisor",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "userLoginEmailQuery",
    "id": null,
    "text": "query userLoginEmailQuery {\n  ...Login_data\n}\n\nfragment Login_data on Query {\n  me {\n    isAdvisor\n    id\n  }\n}\n",
    "metadata": {}
  }
};
// prettier-ignore
(node/*: any*/).hash = '8cd73f9d7481e227ff53873300635df2';
module.exports = node;
