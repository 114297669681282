/**
 * @flow
 * @relayHash 77f7bf3ad70f9b75b6707d56c54dc3c9
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type Layout_data$ref = any;
type MyRequests_data$ref = any;
export type requestsListQueryVariables = {||};
export type requestsListQueryResponse = {|
  +$fragmentRefs: Layout_data$ref & MyRequests_data$ref
|};
export type requestsListQuery = {|
  variables: requestsListQueryVariables,
  response: requestsListQueryResponse,
|};
*/


/*
query requestsListQuery {
  ...Layout_data
  ...MyRequests_data
}

fragment Layout_data on Query {
  me {
    ...Toolbar_me
    ...AutoUpdater_user
    id
    isAdvisor
  }
  ...Toolbar_data
  ...Monitor_data
}

fragment MyRequests_data on Query {
  requests(filter: "is:mine") {
    edges {
      node {
        id
        ...RequestCard_data
      }
    }
  }
}

fragment RequestCard_data on Request {
  id
  text(truncate: 180)
  fullText: text
  createdAt(format: "MMM Do, YYYY")
  responses {
    id
    author {
      id
      photoURL
      displayName
    }
  }
}

fragment Toolbar_me on User {
  id
  username
  displayName
  photoURL
  isAdvisor
}

fragment AutoUpdater_user on User {
  id
  timeZone
}

fragment Toolbar_data on Query {
  unreadMessageCount
}

fragment Monitor_data on Query {
  unreadMessageCount
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "displayName",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "photoURL",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "requestsListQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "FragmentSpread",
        "name": "Layout_data",
        "args": null
      },
      {
        "kind": "FragmentSpread",
        "name": "MyRequests_data",
        "args": null
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "requestsListQuery",
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "me",
        "storageKey": null,
        "args": null,
        "concreteType": "User",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "username",
            "args": null,
            "storageKey": null
          },
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "isAdvisor",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "timeZone",
            "args": null,
            "storageKey": null
          }
        ]
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "unreadMessageCount",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "requests",
        "storageKey": "requests(filter:\"is:mine\")",
        "args": [
          {
            "kind": "Literal",
            "name": "filter",
            "value": "is:mine"
          }
        ],
        "concreteType": "RequestConnection",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "edges",
            "storageKey": null,
            "args": null,
            "concreteType": "RequestEdge",
            "plural": true,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "Request",
                "plural": false,
                "selections": [
                  (v0/*: any*/),
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "text",
                    "args": [
                      {
                        "kind": "Literal",
                        "name": "truncate",
                        "value": 180
                      }
                    ],
                    "storageKey": "text(truncate:180)"
                  },
                  {
                    "kind": "ScalarField",
                    "alias": "fullText",
                    "name": "text",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "createdAt",
                    "args": [
                      {
                        "kind": "Literal",
                        "name": "format",
                        "value": "MMM Do, YYYY"
                      }
                    ],
                    "storageKey": "createdAt(format:\"MMM Do, YYYY\")"
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "responses",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Response",
                    "plural": true,
                    "selections": [
                      (v0/*: any*/),
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "author",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "User",
                        "plural": false,
                        "selections": [
                          (v0/*: any*/),
                          (v2/*: any*/),
                          (v1/*: any*/)
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "requestsListQuery",
    "id": null,
    "text": "query requestsListQuery {\n  ...Layout_data\n  ...MyRequests_data\n}\n\nfragment Layout_data on Query {\n  me {\n    ...Toolbar_me\n    ...AutoUpdater_user\n    id\n    isAdvisor\n  }\n  ...Toolbar_data\n  ...Monitor_data\n}\n\nfragment MyRequests_data on Query {\n  requests(filter: \"is:mine\") {\n    edges {\n      node {\n        id\n        ...RequestCard_data\n      }\n    }\n  }\n}\n\nfragment RequestCard_data on Request {\n  id\n  text(truncate: 180)\n  fullText: text\n  createdAt(format: \"MMM Do, YYYY\")\n  responses {\n    id\n    author {\n      id\n      photoURL\n      displayName\n    }\n  }\n}\n\nfragment Toolbar_me on User {\n  id\n  username\n  displayName\n  photoURL\n  isAdvisor\n}\n\nfragment AutoUpdater_user on User {\n  id\n  timeZone\n}\n\nfragment Toolbar_data on Query {\n  unreadMessageCount\n}\n\nfragment Monitor_data on Query {\n  unreadMessageCount\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '68cae235c37697934d62395dbb8e690f';
module.exports = node;
