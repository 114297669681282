/**
 * @flow
 * @relayHash 8da4203543c957d80ed34a2a45cba5bc
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type Monitor_data$ref = any;
export type MonitorQueryVariables = {||};
export type MonitorQueryResponse = {|
  +$fragmentRefs: Monitor_data$ref
|};
export type MonitorQuery = {|
  variables: MonitorQueryVariables,
  response: MonitorQueryResponse,
|};
*/


/*
query MonitorQuery {
  ...Monitor_data
}

fragment Monitor_data on Query {
  unreadMessageCount
}
*/

const node/*: ConcreteRequest*/ = {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "MonitorQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "FragmentSpread",
        "name": "Monitor_data",
        "args": null
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "MonitorQuery",
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "unreadMessageCount",
        "args": null,
        "storageKey": null
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "MonitorQuery",
    "id": null,
    "text": "query MonitorQuery {\n  ...Monitor_data\n}\n\nfragment Monitor_data on Query {\n  unreadMessageCount\n}\n",
    "metadata": {}
  }
};
// prettier-ignore
(node/*: any*/).hash = '35ab89e125e3abc7716b430247aef980';
module.exports = node;
