/* @flow */

import React from 'react';
import { graphql } from 'relay-runtime';
import Layout from '../common/Layout';

export default [
  {
    path: '/requests',
    query: graphql`
      query requestsListQuery {
        ...Layout_data
        ...MyRequests_data
      }
    `,
    components: () => [
      import(/* webpackChunkName: 'myrequests' */ './MyRequests'),
    ],
    render: ([MyRequests], data) => ({
      title: 'My Requests',
      component: (
        <Layout data={data}>
          <MyRequests data={data} />
        </Layout>
      ),
      chunks: ['myrequests'],
    }),
  },
  {
    path: '/requests/:id',
    query: graphql`
      query requestsDetailsQuery($id: ID!) {
        ...Layout_data
        request: node(id: $id) {
          ...RequestDetails_data
        }
      }
    `,
    components: () => [
      import(/* webpackChunkName: 'requests' */ './RequestDetails'),
    ],
    render: ([RequestDetails], data) => ({
      title: 'Request',
      component: (
        <Layout data={data}>
          <RequestDetails data={data.request} />
        </Layout>
      ),
      chunks: ['requests'],
    }),
  },
];
