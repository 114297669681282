/**
 * @flow
 * @relayHash 2aa9b3497c5b9cbbaaa2f6ac7d432d52
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type Layout_data$ref = any;
type Settings_data$ref = any;
export type userSettingsQueryVariables = {||};
export type userSettingsQueryResponse = {|
  +me: ?{|
    +id: string
  |},
  +$fragmentRefs: Layout_data$ref & Settings_data$ref,
|};
export type userSettingsQuery = {|
  variables: userSettingsQueryVariables,
  response: userSettingsQueryResponse,
|};
*/


/*
query userSettingsQuery {
  ...Layout_data
  ...Settings_data
  me {
    id
  }
}

fragment Layout_data on Query {
  me {
    ...Toolbar_me
    ...AutoUpdater_user
    id
    isAdvisor
  }
  ...Toolbar_data
  ...Monitor_data
}

fragment Settings_data on Query {
  me {
    id
    displayName
    photoURL
    isAdvisor
    settings {
      notifications {
        advisorDailyDigestEmail
        advisorMeetingBookedSMS
        advisorMeetingBookedEmail
        seekerMeetingRecordingEmail
        seekerMeetingReminder1hrSMS
        advisorMeetingRecordingEmail
        advisorMeetingReminder1hrSMS
        seekerMeetingLateReminderSMS
        seekerMeetingReminder5minSMS
        advisorMeetingLateReminderSMS
        advisorMeetingReminder5minSMS
        advisorMessageFromSeekerEmail
        seekerMessageFromAdvisorEmail
        seekerMeetingConfirmationEmail
        seekerMeetingReminder24hrEmail
        advisorMeetingReminder24hrEmail
        seekerGetsResponseFromAdvisorEmail
      }
    }
  }
}

fragment Toolbar_me on User {
  id
  username
  displayName
  photoURL
  isAdvisor
}

fragment AutoUpdater_user on User {
  id
  timeZone
}

fragment Toolbar_data on Query {
  unreadMessageCount
}

fragment Monitor_data on Query {
  unreadMessageCount
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "userSettingsQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "me",
        "storageKey": null,
        "args": null,
        "concreteType": "User",
        "plural": false,
        "selections": [
          (v0/*: any*/)
        ]
      },
      {
        "kind": "FragmentSpread",
        "name": "Layout_data",
        "args": null
      },
      {
        "kind": "FragmentSpread",
        "name": "Settings_data",
        "args": null
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "userSettingsQuery",
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "me",
        "storageKey": null,
        "args": null,
        "concreteType": "User",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "username",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "displayName",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "photoURL",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "isAdvisor",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "timeZone",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "settings",
            "storageKey": null,
            "args": null,
            "concreteType": "UserSettings",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "notifications",
                "storageKey": null,
                "args": null,
                "concreteType": "NotificationSettings",
                "plural": false,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "advisorDailyDigestEmail",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "advisorMeetingBookedSMS",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "advisorMeetingBookedEmail",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "seekerMeetingRecordingEmail",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "seekerMeetingReminder1hrSMS",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "advisorMeetingRecordingEmail",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "advisorMeetingReminder1hrSMS",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "seekerMeetingLateReminderSMS",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "seekerMeetingReminder5minSMS",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "advisorMeetingLateReminderSMS",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "advisorMeetingReminder5minSMS",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "advisorMessageFromSeekerEmail",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "seekerMessageFromAdvisorEmail",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "seekerMeetingConfirmationEmail",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "seekerMeetingReminder24hrEmail",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "advisorMeetingReminder24hrEmail",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "seekerGetsResponseFromAdvisorEmail",
                    "args": null,
                    "storageKey": null
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "unreadMessageCount",
        "args": null,
        "storageKey": null
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "userSettingsQuery",
    "id": null,
    "text": "query userSettingsQuery {\n  ...Layout_data\n  ...Settings_data\n  me {\n    id\n  }\n}\n\nfragment Layout_data on Query {\n  me {\n    ...Toolbar_me\n    ...AutoUpdater_user\n    id\n    isAdvisor\n  }\n  ...Toolbar_data\n  ...Monitor_data\n}\n\nfragment Settings_data on Query {\n  me {\n    id\n    displayName\n    photoURL\n    isAdvisor\n    settings {\n      notifications {\n        advisorDailyDigestEmail\n        advisorMeetingBookedSMS\n        advisorMeetingBookedEmail\n        seekerMeetingRecordingEmail\n        seekerMeetingReminder1hrSMS\n        advisorMeetingRecordingEmail\n        advisorMeetingReminder1hrSMS\n        seekerMeetingLateReminderSMS\n        seekerMeetingReminder5minSMS\n        advisorMeetingLateReminderSMS\n        advisorMeetingReminder5minSMS\n        advisorMessageFromSeekerEmail\n        seekerMessageFromAdvisorEmail\n        seekerMeetingConfirmationEmail\n        seekerMeetingReminder24hrEmail\n        advisorMeetingReminder24hrEmail\n        seekerGetsResponseFromAdvisorEmail\n      }\n    }\n  }\n}\n\nfragment Toolbar_me on User {\n  id\n  username\n  displayName\n  photoURL\n  isAdvisor\n}\n\nfragment AutoUpdater_user on User {\n  id\n  timeZone\n}\n\nfragment Toolbar_data on Query {\n  unreadMessageCount\n}\n\nfragment Monitor_data on Query {\n  unreadMessageCount\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '46578cbbd97f92db508444d194e0aa26';
module.exports = node;
