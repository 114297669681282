import React from 'react';
import Drawer from '@material-ui/core/Drawer';
import Badge from '@material-ui/core/Badge';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/styles';

import menu from '../menu';
import Link from './Link';
import LoginLink from './LoginLink';
import { useViewMode } from '../hooks';

const useStyles = makeStyles(theme => ({
  styledBadge: {
    top: 5,
    right: 15,
    backgroundColor: theme.palette.error.dark,
    color: theme.palette.common.white,
  },
}));

function LayoutDrawer(props) {
  const { data: user, unreadMessageCount, ...other } = props;
  const viewMode = useViewMode();
  const s = useStyles();

  const menuList = menu(user, viewMode).map(x => {
    if (x.text === 'Messages') {
      return (
        <React.Fragment key={x.key}>
          {unreadMessageCount ? (
            <Badge
              classes={{ badge: s.styledBadge }}
              badgeContent={unreadMessageCount}
            >
              <ListItem
                button
                href={x.href}
                onClick={props.onClose}
                component={x.href === '/login' ? LoginLink : Link}
              >
                <ListItemIcon>{React.createElement(x.icon)}</ListItemIcon>
                <ListItemText primary={x.text} />
              </ListItem>
            </Badge>
          ) : (
            <ListItem
              button
              href={x.href}
              onClick={props.onClose}
              component={x.href === '/login' ? LoginLink : Link}
            >
              <ListItemIcon>{React.createElement(x.icon)}</ListItemIcon>
              <ListItemText primary={x.text} />
            </ListItem>
          )}
        </React.Fragment>
      );
    } else if (user && x.text === 'Become an Advisor') {
      return (
        <ListItem
          key={x.key}
          button
          href="/apply"
          onClick={props.onClose}
          component={Link}
        >
          <ListItemIcon>{React.createElement(x.icon)}</ListItemIcon>
          <ListItemText primary={x.text} />
        </ListItem>
      );
    } else {
      return (
        <ListItem
          key={x.key}
          button
          href={x.href}
          component={x.href === '/login' ? LoginLink : Link}
          onClick={props.onClose}
        >
          <ListItemIcon>{React.createElement(x.icon)}</ListItemIcon>
          <ListItemText primary={x.text} />
        </ListItem>
      );
    }
  });

  return (
    <Drawer variant="temporary" ModalProps={{ keepMounted: true }} {...other}>
      <List component="nav">{menuList}</List>
    </Drawer>
  );
}

export default LayoutDrawer;
