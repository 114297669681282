/* @flow */

import React from 'react';
import { graphql } from 'relay-runtime';

import Layout from '../common/Layout';
import { AdvisorUriPrefix } from '../constants';

export default [
  {
    path: '',
    query: graphql`
      query dashboardUserQuery {
        me {
          isAdvisor
        }
        ...UserDashboard_data
        ...Layout_data
      }
    `,
    components: () => [
      import(/* webpackChunkName: 'user-dashboard' */ './UserDashboard'),
    ],
    render: ([UserDashboard], data, ctx) =>
      // If view mode was not set yet and the current user is an advisor
      // redirect him or her to the Advisor Dashboard page.
      typeof window !== 'undefined' &&
      !window.sessionStorage.getItem('viewMode') &&
      (data.me && data.me.isAdvisor)
        ? { redirect: AdvisorUriPrefix }
        : {
            title: 'Dashboard • Prepdup',
            component: (
              <Layout data={data} contentWidth="medium">
                <UserDashboard data={data} />
              </Layout>
            ),
            chunks: ['user-dashboard'],
          },
  },
];
