/* @flow */

import React from 'react';
import { graphql } from 'relay-runtime';
import Layout from '../common/Layout';

export default [
  {
    path: '/advising',
    query: graphql`
      query advisorDashboardQuery {
        ...Layout_data
        ...AdvisorDashboard_data
      }
    `,
    components: () => [
      import(/* webpackChunkName: 'adv-dashboard' */ './AdvisorDashboard'),
    ],
    render: ([AdvisorDashboard], data) => ({
      title: 'Dashboard • Prepdup Advisor',
      component: (
        <Layout data={data} contentWidth="medium">
          <AdvisorDashboard data={data} />
        </Layout>
      ),
      chunks: ['adv-dashboard'],
    }),
  },
  {
    path: '/advising/requests',
    query: graphql`
      query advisorRequestsQuery {
        ...Layout_data
        ...AdvisorRequestsNavigation_data
        ...AdvisorRequests_data
      }
    `,
    components: () => [
      import(/* webpackChunkName: 'adv-requests' */ './AdvisorRequests'),
      import(/* webpackChunkName: 'adv-requests' */ './AdvisorRequestsNavigation'),
    ],
    render: ([AdvisorRequests, AdvisorRequestsNavigation], data) => ({
      title: 'Requests • Prepdup Advisor',
      component: (
        <Layout data={data}>
          <AdvisorRequestsNavigation data={data} selectedIndex={0} />
          <AdvisorRequests data={data} />
        </Layout>
      ),
      chunks: ['adv-requests'],
    }),
  },
  {
    path: '/advising/responses',
    query: graphql`
      query advisorResponsesQuery {
        ...Layout_data
        ...AdvisorRequestsNavigation_data
        ...AdvisorResponses_data
      }
    `,
    components: () => [
      import(/* webpackChunkName: 'adv-responses' */ './AdvisorResponses'),
      import(/* webpackChunkName: 'adv-requests' */ './AdvisorRequestsNavigation'),
    ],
    render: ([AdvisorResponses, AdvisorRequestsNavigation], data) => ({
      title: 'Responses • Prepdup Advisor',
      component: (
        <Layout data={data}>
          <AdvisorRequestsNavigation data={data} selectedIndex={1} />
          <AdvisorResponses data={data} />
        </Layout>
      ),
      chunks: ['adv-responses'],
    }),
  },
  {
    path: '/advising/payments',
    query: graphql`
      query advisorPaymentsQuery {
        ...Layout_data
        ...AdvisorPayments_data
      }
    `,
    components: () => [
      import(/* webpackChunkName: 'adv-payments' */ './AdvisorPayments'),
    ],
    render: ([AdvisorPayments], data) => ({
      title: 'Payments • Prepdup Advisor',
      component: (
        <Layout data={data}>
          <AdvisorPayments data={data} />
        </Layout>
      ),
      chunks: ['adv-payments'],
    }),
  },
];
