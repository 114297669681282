/**
 * @flow
 * @relayHash 6ab21e255e41ebcbdab0befc262bdcf8
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type Layout_data$ref = any;
export type pagesAboutQueryVariables = {||};
export type pagesAboutQueryResponse = {|
  +me: ?{|
    +id: string
  |},
  +$fragmentRefs: Layout_data$ref,
|};
export type pagesAboutQuery = {|
  variables: pagesAboutQueryVariables,
  response: pagesAboutQueryResponse,
|};
*/


/*
query pagesAboutQuery {
  ...Layout_data
  me {
    id
  }
}

fragment Layout_data on Query {
  me {
    ...Toolbar_me
    ...AutoUpdater_user
    id
    isAdvisor
  }
  ...Toolbar_data
  ...Monitor_data
}

fragment Toolbar_me on User {
  id
  username
  displayName
  photoURL
  isAdvisor
}

fragment AutoUpdater_user on User {
  id
  timeZone
}

fragment Toolbar_data on Query {
  unreadMessageCount
}

fragment Monitor_data on Query {
  unreadMessageCount
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "pagesAboutQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "me",
        "storageKey": null,
        "args": null,
        "concreteType": "User",
        "plural": false,
        "selections": [
          (v0/*: any*/)
        ]
      },
      {
        "kind": "FragmentSpread",
        "name": "Layout_data",
        "args": null
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "pagesAboutQuery",
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "me",
        "storageKey": null,
        "args": null,
        "concreteType": "User",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "username",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "displayName",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "photoURL",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "isAdvisor",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "timeZone",
            "args": null,
            "storageKey": null
          }
        ]
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "unreadMessageCount",
        "args": null,
        "storageKey": null
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "pagesAboutQuery",
    "id": null,
    "text": "query pagesAboutQuery {\n  ...Layout_data\n  me {\n    id\n  }\n}\n\nfragment Layout_data on Query {\n  me {\n    ...Toolbar_me\n    ...AutoUpdater_user\n    id\n    isAdvisor\n  }\n  ...Toolbar_data\n  ...Monitor_data\n}\n\nfragment Toolbar_me on User {\n  id\n  username\n  displayName\n  photoURL\n  isAdvisor\n}\n\nfragment AutoUpdater_user on User {\n  id\n  timeZone\n}\n\nfragment Toolbar_data on Query {\n  unreadMessageCount\n}\n\nfragment Monitor_data on Query {\n  unreadMessageCount\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'b8537467c3c23887b1db3402ed4cfdf0';
module.exports = node;
