/**
 * @flow
 * @relayHash 4cae6e3bb083b4c089e58c289e35f641
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type Apply_data$ref = any;
type Layout_data$ref = any;
export type userQueryVariables = {||};
export type userQueryResponse = {|
  +me: ?{|
    +isAdvisor: ?boolean
  |},
  +$fragmentRefs: Layout_data$ref & Apply_data$ref,
|};
export type userQuery = {|
  variables: userQueryVariables,
  response: userQueryResponse,
|};
*/


/*
query userQuery {
  me {
    isAdvisor
    id
  }
  ...Layout_data
  ...Apply_data
}

fragment Layout_data on Query {
  me {
    ...Toolbar_me
    ...AutoUpdater_user
    id
    isAdvisor
  }
  ...Toolbar_data
  ...Monitor_data
}

fragment Apply_data on Query {
  me {
    id
    about
    displayName
    photoURL
    linkedin
    advisorStatus
    phoneVerified
  }
  connection(username: "xyz") {
    id
  }
}

fragment Toolbar_me on User {
  id
  username
  displayName
  photoURL
  isAdvisor
}

fragment AutoUpdater_user on User {
  id
  timeZone
}

fragment Toolbar_data on Query {
  unreadMessageCount
}

fragment Monitor_data on Query {
  unreadMessageCount
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "isAdvisor",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "userQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "me",
        "storageKey": null,
        "args": null,
        "concreteType": "User",
        "plural": false,
        "selections": [
          (v0/*: any*/)
        ]
      },
      {
        "kind": "FragmentSpread",
        "name": "Layout_data",
        "args": null
      },
      {
        "kind": "FragmentSpread",
        "name": "Apply_data",
        "args": null
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "userQuery",
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "me",
        "storageKey": null,
        "args": null,
        "concreteType": "User",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "username",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "displayName",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "photoURL",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "timeZone",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "about",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "linkedin",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "advisorStatus",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "phoneVerified",
            "args": null,
            "storageKey": null
          }
        ]
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "unreadMessageCount",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "connection",
        "storageKey": "connection(username:\"xyz\")",
        "args": [
          {
            "kind": "Literal",
            "name": "username",
            "value": "xyz"
          }
        ],
        "concreteType": "Connection",
        "plural": false,
        "selections": [
          (v1/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "userQuery",
    "id": null,
    "text": "query userQuery {\n  me {\n    isAdvisor\n    id\n  }\n  ...Layout_data\n  ...Apply_data\n}\n\nfragment Layout_data on Query {\n  me {\n    ...Toolbar_me\n    ...AutoUpdater_user\n    id\n    isAdvisor\n  }\n  ...Toolbar_data\n  ...Monitor_data\n}\n\nfragment Apply_data on Query {\n  me {\n    id\n    about\n    displayName\n    photoURL\n    linkedin\n    advisorStatus\n    phoneVerified\n  }\n  connection(username: \"xyz\") {\n    id\n  }\n}\n\nfragment Toolbar_me on User {\n  id\n  username\n  displayName\n  photoURL\n  isAdvisor\n}\n\nfragment AutoUpdater_user on User {\n  id\n  timeZone\n}\n\nfragment Toolbar_data on Query {\n  unreadMessageCount\n}\n\nfragment Monitor_data on Query {\n  unreadMessageCount\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'f19e22d6ae46138131d9fc3bcae963fe';
module.exports = node;
