import React from 'react';
import Link from './Link';
import { useViewMode } from '../hooks';
import { ViewMode, AdvisorUriPrefix } from '../constants';

const ViewLink = React.forwardRef(function ViewLink(props, ref) {
  const { href, ...other } = props;
  const viewMode = useViewMode();

  const prefixedHref =
    viewMode === ViewMode.ADVISOR ? `${AdvisorUriPrefix}${href}` : href;

  return <Link ref={ref} href={prefixedHref} {...other} />;
});

export default ViewLink;
