/* @flow */

import React, { useState, useLayoutEffect } from 'react';
import MenuIcon from '@material-ui/icons/Menu';
import AppBar from '@material-ui/core/AppBar';
import MUIToolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Badge from '@material-ui/core/Badge';
import styled from 'styled-components';
import { makeStyles } from '@material-ui/styles';
import { graphql, createFragmentContainer } from 'react-relay';

import Link from './Link';
import ViewLink from './ViewLink';
import LoginLink from './LoginLink';
import PrepdupLogo from '../icons/PrepdupLogo';
import PrepdupAdvisingLogo from '../icons/PrepdupAdvisingLogo';
import LoadingBar from './LoadingBar';
import LayoutDrawer from './LayoutDrawer';
import { ViewMode } from '../constants';
import { useHistory, useReset, useViewMode } from '../hooks';
import { ToolbarWithDrawer } from './PublicLayout';
import { onScroll, toggleViewMode } from '../utils';

const StyledTitle = styled(Typography)`
  && {
    padding: 0;
    margin: auto;
    margin-right: 1em;
    text-align: left;
    text-decoration: none;
    cursor: pointer;
  }
`;

const MenuButton = styled(IconButton)`
  @media (min-width: 721px) {
    && {
      display: none;
    }
  }
`;

const StyledButton = styled(Button)`
  && {
    text-transform: none;

    @media (max-width: 720px) {
      display: none;
    }
  }
`;

const StyledMenu = styled(Menu)`
  && {
    top: 50px;
  }
`;

const StyledMenuItem = styled(MenuItem)`
  && {
    padding-top: 0;
    padding-bottom: 0;
  }
`;

const SmallAvatar = styled(Avatar)`
  && {
    width: 30px;
    height: 30px;
  }
`;

const Spacer = styled.div`
  flex-grow: 1;
`;

const StyledDiv = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

const useStyles = makeStyles(theme => ({
  styledBadge: {
    top: -3,
    right: 0,
    backgroundColor: theme.palette.error.dark,
    color: theme.palette.common.white,
    [theme.breakpoints.down('721')]: {
      display: 'none',
    },
  },
  hamburgerBadge: {
    top: 10,
    right: 9,
    height: 8,
    width: 8,
    backgroundColor: theme.palette.error.dark,
    color: theme.palette.error.dark,
    [theme.breakpoints.up('721')]: {
      display: 'none',
    },
  },
}));

function Title(props) {
  const { me, mode } = props;

  return (
    <StyledTitle
      variant="h6"
      color="inherit"
      component={Link}
      href={me && me.isAdvisor && mode === ViewMode.ADVISOR ? '/advising' : '/'}
    >
      {me && me.isAdvisor && mode === ViewMode.ADVISOR ? (
        <PrepdupAdvisingLogo />
      ) : (
        <PrepdupLogo />
      )}
    </StyledTitle>
  );
}

function GuestMenuItems() {
  return <Spacer />;
}

function UserMenuItems(props) {
  const { isAdvisor, unreadMessageCount } = props;
  const s = useStyles();

  return (
    <StyledDiv>
      <Spacer />
      {!isAdvisor && (
        <StyledButton href="/apply" component={Link}>
          Become an Advisor
        </StyledButton>
      )}
      <StyledButton href="/meetings" component={ViewLink}>
        Meetings
      </StyledButton>
      <StyledButton href="/requests" component={ViewLink}>
        My Requests
      </StyledButton>
      {unreadMessageCount ? (
        <Badge
          classes={{ badge: s.styledBadge }}
          badgeContent={unreadMessageCount}
        >
          <StyledButton href="/messages" component={ViewLink}>
            Messages
          </StyledButton>
        </Badge>
      ) : (
        <StyledButton href="/messages" component={ViewLink}>
          Messages
        </StyledButton>
      )}
    </StyledDiv>
  );
}

function AdvisorMenuItems(props) {
  const { unreadMessageCount } = props;
  const s = useStyles();

  return (
    <StyledDiv>
      {unreadMessageCount ? (
        <Badge
          classes={{ badge: s.styledBadge }}
          badgeContent={unreadMessageCount}
        >
          <StyledButton href="/messages" component={ViewLink}>
            Messages
          </StyledButton>
        </Badge>
      ) : (
        <StyledButton href="/messages" component={ViewLink}>
          Messages
        </StyledButton>
      )}

      <StyledButton href="/requests" component={ViewLink}>
        Requests
      </StyledButton>
      <StyledButton href="/meetings" component={ViewLink}>
        Meetings
      </StyledButton>
      <Spacer />
    </StyledDiv>
  );
}

function MenuItems(props) {
  const { me, mode, unreadMessageCount } = props;

  switch ((me && mode) || ViewMode.GUEST) {
    case ViewMode.GUEST:
      return <GuestMenuItems />;
    case ViewMode.SEEKER:
      return (
        <UserMenuItems
          isAdvisor={me && me.isAdvisor}
          unreadMessageCount={unreadMessageCount}
        />
      );
    case ViewMode.ADVISOR:
      return <AdvisorMenuItems unreadMessageCount={unreadMessageCount} />;
    default:
      throw new Error();
  }
}

function Toolbar(props) {
  const { me, data, onMenuClick, open, hideLinks } = props;
  const history = useHistory();
  const reset = useReset();
  const viewMode = useViewMode();
  const [menuAnchorEl, setMenuAnchorEl] = useState();
  const [shadow, setShadow] = useState(false);
  const s = useStyles();
  useLayoutEffect(
    () =>
      onScroll(scrollY => {
        if (scrollY > 60 && !shadow) {
          setShadow(true);
        } else if (scrollY < 60 && shadow) {
          setShadow(false);
        }
      }),
    [],
  );

  function openMenu(event) {
    setMenuAnchorEl(event.currentTarget);
  }

  function closeMenu() {
    setMenuAnchorEl(null);
  }

  async function switchViewMode() {
    setMenuAnchorEl(null);
    toggleViewMode(history, viewMode);
  }

  async function logOut() {
    setMenuAnchorEl(null);
    await fetch('/login/clear', {
      method: 'POST',
      credentials: 'include',
    });

    reset();
    history.push('/');
  }

  return me ? (
    <>
      <AppBar color="inherit" position="fixed">
        <LoadingBar />
        <MUIToolbar color="black">
          {me && data.unreadMessageCount ? (
            <Badge classes={{ badge: s.hamburgerBadge }} badgeContent={0}>
              <MenuButton
                color="inherit"
                area-label="Open drawer"
                onClick={props.onMenuClick}
              >
                <MenuIcon />
              </MenuButton>
            </Badge>
          ) : (
            <MenuButton
              color="inherit"
              area-label="Open drawer"
              onClick={props.onMenuClick}
            >
              <MenuIcon />
            </MenuButton>
          )}

          <Title me={me} mode={viewMode} />
          <MenuItems
            me={me}
            mode={viewMode}
            unreadMessageCount={data.unreadMessageCount}
          />
          {me && (
            <>
              <IconButton
                aria-owns={Boolean(menuAnchorEl) ? 'account-menu' : null}
                aria-haspopup="true"
                onClick={openMenu}
                color="primary"
              >
                <SmallAvatar src={me.photoURL} />
              </IconButton>
              <StyledMenu
                id="account-menu"
                anchorEl={menuAnchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(menuAnchorEl)}
                onClose={closeMenu}
              >
                {me.isAdvisor && (
                  <React.Fragment>
                    {viewMode === 'ADVISOR' && (
                      <React.Fragment>
                        <StyledMenuItem
                          href={`/@${me.username}`}
                          component={ViewLink}
                          onClick={closeMenu}
                        >
                          Profile
                        </StyledMenuItem>
                        <StyledMenuItem
                          href={`/availability`}
                          component={ViewLink}
                          onClick={closeMenu}
                        >
                          Availability
                        </StyledMenuItem>
                      </React.Fragment>
                    )}
                    <StyledMenuItem onClick={switchViewMode}>
                      Switch to {{ SEEKER: 'Give', ADVISOR: 'Get' }[viewMode]}{' '}
                      Advice
                    </StyledMenuItem>
                  </React.Fragment>
                )}
                <StyledMenuItem
                  onClick={closeMenu}
                  href="/settings"
                  component={Link}
                >
                  Settings
                </StyledMenuItem>
                <StyledMenuItem onClick={logOut}>Log Out</StyledMenuItem>
              </StyledMenu>
            </>
          )}
          {me === null && (
            <StyledButton color="inherit" key="SignIn" component={LoginLink}>
              Sign In
            </StyledButton>
          )}
        </MUIToolbar>
      </AppBar>
      <LayoutDrawer
        data={me}
        open={open}
        onClose={onMenuClick}
        unreadMessageCount={data.unreadMessageCount}
      />
    </>
  ) : (
    <ToolbarWithDrawer
      toggleDrawer={onMenuClick}
      drawerOpen={open}
      shadow={shadow}
      earlyAccess={hideLinks}
    />
  );
}

export default createFragmentContainer(Toolbar, {
  data: graphql`
    fragment Toolbar_data on Query {
      unreadMessageCount
    }
  `,
  me: graphql`
    fragment Toolbar_me on User {
      id
      username
      displayName
      photoURL
      isAdvisor
    }
  `,
});
