/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type Toolbar_data$ref: FragmentReference;
declare export opaque type Toolbar_data$fragmentType: Toolbar_data$ref;
export type Toolbar_data = {|
  +unreadMessageCount: ?number,
  +$refType: Toolbar_data$ref,
|};
export type Toolbar_data$data = Toolbar_data;
export type Toolbar_data$key = {
  +$data?: Toolbar_data$data,
  +$fragmentRefs: Toolbar_data$ref,
};
*/


const node/*: ReaderFragment*/ = {
  "kind": "Fragment",
  "name": "Toolbar_data",
  "type": "Query",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "unreadMessageCount",
      "args": null,
      "storageKey": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '7fe6ee005f501634bcb94771d6db50df';
module.exports = node;
