/**
 * @flow
 * @relayHash 8114cb508fd9f87223ae981451c67844
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type Layout_data$ref = any;
type MeetingCompositionView_data$ref = any;
export type meetingsMeetingCompositionViewQueryVariables = {|
  id: string
|};
export type meetingsMeetingCompositionViewQueryResponse = {|
  +meeting: ?{|
    +$fragmentRefs: MeetingCompositionView_data$ref
  |},
  +$fragmentRefs: Layout_data$ref,
|};
export type meetingsMeetingCompositionViewQuery = {|
  variables: meetingsMeetingCompositionViewQueryVariables,
  response: meetingsMeetingCompositionViewQueryResponse,
|};
*/


/*
query meetingsMeetingCompositionViewQuery(
  $id: ID!
) {
  ...Layout_data
  meeting: node(id: $id) {
    __typename
    ...MeetingCompositionView_data
    id
  }
}

fragment Layout_data on Query {
  me {
    ...Toolbar_me
    ...AutoUpdater_user
    id
    isAdvisor
  }
  ...Toolbar_data
  ...Monitor_data
}

fragment MeetingCompositionView_data on Meeting {
  id
  room {
    compositionStatus
    id
  }
}

fragment Toolbar_me on User {
  id
  username
  displayName
  photoURL
  isAdvisor
}

fragment AutoUpdater_user on User {
  id
  timeZone
}

fragment Toolbar_data on Query {
  unreadMessageCount
}

fragment Monitor_data on Query {
  unreadMessageCount
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "id",
    "type": "ID!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "meetingsMeetingCompositionViewQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": "meeting",
        "name": "node",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "MeetingCompositionView_data",
            "args": null
          }
        ]
      },
      {
        "kind": "FragmentSpread",
        "name": "Layout_data",
        "args": null
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "meetingsMeetingCompositionViewQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "me",
        "storageKey": null,
        "args": null,
        "concreteType": "User",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "username",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "displayName",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "photoURL",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "isAdvisor",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "timeZone",
            "args": null,
            "storageKey": null
          }
        ]
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "unreadMessageCount",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "LinkedField",
        "alias": "meeting",
        "name": "node",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "__typename",
            "args": null,
            "storageKey": null
          },
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "type": "Meeting",
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "room",
                "storageKey": null,
                "args": null,
                "concreteType": "Room",
                "plural": false,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "compositionStatus",
                    "args": null,
                    "storageKey": null
                  },
                  (v2/*: any*/)
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "meetingsMeetingCompositionViewQuery",
    "id": null,
    "text": "query meetingsMeetingCompositionViewQuery(\n  $id: ID!\n) {\n  ...Layout_data\n  meeting: node(id: $id) {\n    __typename\n    ...MeetingCompositionView_data\n    id\n  }\n}\n\nfragment Layout_data on Query {\n  me {\n    ...Toolbar_me\n    ...AutoUpdater_user\n    id\n    isAdvisor\n  }\n  ...Toolbar_data\n  ...Monitor_data\n}\n\nfragment MeetingCompositionView_data on Meeting {\n  id\n  room {\n    compositionStatus\n    id\n  }\n}\n\nfragment Toolbar_me on User {\n  id\n  username\n  displayName\n  photoURL\n  isAdvisor\n}\n\nfragment AutoUpdater_user on User {\n  id\n  timeZone\n}\n\nfragment Toolbar_data on Query {\n  unreadMessageCount\n}\n\nfragment Monitor_data on Query {\n  unreadMessageCount\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '748176553ba951b13f7d1ed6da26dca5';
module.exports = node;
