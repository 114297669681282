/* @flow */

import React from 'react';
import { ReactRelayContext } from 'react-relay';
import { fetchQuery, graphql } from 'react-relay';
import debounce from 'lodash/debounce';
import { ViewMode } from './constants';

const history = { location: { pathname: '/' } };

export const ConfigContext = React.createContext({});
export const HistoryContext = React.createContext(history);
export const ViewModeContext = React.createContext(ViewMode.SEEKER);
export const ResetContext = React.createContext(() => {});

export function useConfig() {
  return React.useContext(ConfigContext);
}

export function useHistory() {
  return React.useContext(HistoryContext);
}

export function useViewMode() {
  return React.useContext(ViewModeContext);
}

export function useRelay() {
  return React.useContext(ReactRelayContext);
}

export function useReset() {
  return React.useContext(ResetContext);
}

export const useFirebase = (() => {
  let firebaseUser = null;

  return function useFirebase(firebase, accessToken) {
    const relay = useRelay();
    const [, setUser] = React.useState([firebase, firebaseUser]);
    React.useEffect(() => {
      // Initialize Firebase Client SDK
      if (!firebase.apps.length) {
        firebase.initializeApp(window.config.firebase);
        // TODO: Remove the deprecated 'timestampsInSnapshots' setting
        //       https://firebase.google.com/docs/reference/js/firebase.firestore.Settings.html#timestampsinsnapshots
        firebase.firestore().settings({ timestampsInSnapshots: true });
        firebase.auth().setPersistence(firebase.auth.Auth.Persistence.NONE);
      }

      // Fetch Firebase authentication token from our API
      const query = graphql`
        query hooksUseFirebaseQuery {
          me {
            firebaseToken
          }
        }
      `;

      fetchQuery(relay.environment, query, {}).then(({ me }) => {
        if ((me || {}).firebaseToken) {
          firebase
            .auth()
            .signInWithCustomToken((me || {}).firebaseToken)
            .catch(console.error);
        } else {
          firebase.auth().signOut();
        }
      });

      // Save the current Firebase user to the component's state
      return firebase.auth().onAuthStateChanged(user => {
        if (
          JSON.stringify(firebaseUser && firebaseUser.toJSON()) !==
          JSON.stringify(user && user.toJSON())
        ) {
          setUser((firebaseUser = user));
        }
      });
    }, []);

    return firebaseUser;
  };
})();

export function useScrollY() {
  const [scrollY, setScrollY] = React.useState(0);

  React.useEffect(() => {
    const handleScroll = () => setScrollY(window.pageYOffset);
    window.addEventListener(
      'scroll',
      debounce(handleScroll, 166, { maxWait: 322 }),
    );
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return scrollY;
}
