import React from 'react';
import { useFirebase } from '../hooks';

function InitializeApp(props) {
  useFirebase(props.firebase);
  return null;
}

// Load Firebase SDK on demand for testing purposes:
//
//   const fb = await loadFirebase();
//
class FirebaseLoader extends React.PureComponent {
  constructor(props) {
    super(props);
    if (typeof window !== 'undefined') {
      window.loadFirebase = () =>
        Promise.all([
          import(/* webpackChunkName: 'firebase' */ '@firebase/app'),
          import(/* webpackChunkName: 'firebase' */ '@firebase/auth'),
          import(/* webpackChunkName: 'firebase' */ '@firebase/firestore'),
        ]).then(([{ firebase }]) => {
          this.setState({ firebase, key: Date.now() });
          return firebase;
        });
    }
  }

  state = { firebase: null, key: Date.now() };

  render() {
    const { key, firebase } = this.state;
    return firebase ? <InitializeApp key={key} firebase={firebase} /> : null;
  }
}

export default FirebaseLoader;
