/**
 * @flow
 * @relayHash 0018abe3d1aaa690aa2db77aca6c93f8
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type AdvisorRequestsNavigation_data$ref = any;
type AdvisorRequests_data$ref = any;
type Layout_data$ref = any;
export type advisorRequestsQueryVariables = {||};
export type advisorRequestsQueryResponse = {|
  +$fragmentRefs: Layout_data$ref & AdvisorRequestsNavigation_data$ref & AdvisorRequests_data$ref
|};
export type advisorRequestsQuery = {|
  variables: advisorRequestsQueryVariables,
  response: advisorRequestsQueryResponse,
|};
*/


/*
query advisorRequestsQuery {
  ...Layout_data
  ...AdvisorRequestsNavigation_data
  ...AdvisorRequests_data
}

fragment Layout_data on Query {
  me {
    ...Toolbar_me
    ...AutoUpdater_user
    id
    isAdvisor
  }
  ...Toolbar_data
  ...Monitor_data
}

fragment AdvisorRequestsNavigation_data on Query {
  responses(filter: "is:mine") {
    totalCount
  }
}

fragment AdvisorRequests_data on Query {
  me {
    id
  }
  requests(first: 100) {
    totalCount
    edges {
      node {
        id
        ...AdvisorRequestCard_data
        __typename
      }
      cursor
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}

fragment AdvisorRequestCard_data on Request {
  id
  text(truncate: 180)
  fullText: text
  createdAt(format: "MMM Do, YYYY")
  author {
    id
    displayName
    photoURL
  }
  responses {
    author {
      id
    }
    id
  }
}

fragment Toolbar_me on User {
  id
  username
  displayName
  photoURL
  isAdvisor
}

fragment AutoUpdater_user on User {
  id
  timeZone
}

fragment Toolbar_data on Query {
  unreadMessageCount
}

fragment Monitor_data on Query {
  unreadMessageCount
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "displayName",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "photoURL",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "totalCount",
  "args": null,
  "storageKey": null
},
v4 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 100
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "advisorRequestsQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "FragmentSpread",
        "name": "Layout_data",
        "args": null
      },
      {
        "kind": "FragmentSpread",
        "name": "AdvisorRequestsNavigation_data",
        "args": null
      },
      {
        "kind": "FragmentSpread",
        "name": "AdvisorRequests_data",
        "args": null
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "advisorRequestsQuery",
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "me",
        "storageKey": null,
        "args": null,
        "concreteType": "User",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "username",
            "args": null,
            "storageKey": null
          },
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "isAdvisor",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "timeZone",
            "args": null,
            "storageKey": null
          }
        ]
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "unreadMessageCount",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "responses",
        "storageKey": "responses(filter:\"is:mine\")",
        "args": [
          {
            "kind": "Literal",
            "name": "filter",
            "value": "is:mine"
          }
        ],
        "concreteType": "ResponseConnection",
        "plural": false,
        "selections": [
          (v3/*: any*/)
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "requests",
        "storageKey": "requests(first:100)",
        "args": (v4/*: any*/),
        "concreteType": "RequestConnection",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "edges",
            "storageKey": null,
            "args": null,
            "concreteType": "RequestEdge",
            "plural": true,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "Request",
                "plural": false,
                "selections": [
                  (v0/*: any*/),
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "text",
                    "args": [
                      {
                        "kind": "Literal",
                        "name": "truncate",
                        "value": 180
                      }
                    ],
                    "storageKey": "text(truncate:180)"
                  },
                  {
                    "kind": "ScalarField",
                    "alias": "fullText",
                    "name": "text",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "createdAt",
                    "args": [
                      {
                        "kind": "Literal",
                        "name": "format",
                        "value": "MMM Do, YYYY"
                      }
                    ],
                    "storageKey": "createdAt(format:\"MMM Do, YYYY\")"
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "author",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "User",
                    "plural": false,
                    "selections": [
                      (v0/*: any*/),
                      (v1/*: any*/),
                      (v2/*: any*/)
                    ]
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "responses",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Response",
                    "plural": true,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "author",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "User",
                        "plural": false,
                        "selections": [
                          (v0/*: any*/)
                        ]
                      },
                      (v0/*: any*/)
                    ]
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "__typename",
                    "args": null,
                    "storageKey": null
                  }
                ]
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "cursor",
                "args": null,
                "storageKey": null
              }
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "pageInfo",
            "storageKey": null,
            "args": null,
            "concreteType": "PageInfo",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "endCursor",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "hasNextPage",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      },
      {
        "kind": "LinkedHandle",
        "alias": null,
        "name": "requests",
        "args": (v4/*: any*/),
        "handle": "connection",
        "key": "AdvisorRequests_data_requests",
        "filters": null
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "advisorRequestsQuery",
    "id": null,
    "text": "query advisorRequestsQuery {\n  ...Layout_data\n  ...AdvisorRequestsNavigation_data\n  ...AdvisorRequests_data\n}\n\nfragment Layout_data on Query {\n  me {\n    ...Toolbar_me\n    ...AutoUpdater_user\n    id\n    isAdvisor\n  }\n  ...Toolbar_data\n  ...Monitor_data\n}\n\nfragment AdvisorRequestsNavigation_data on Query {\n  responses(filter: \"is:mine\") {\n    totalCount\n  }\n}\n\nfragment AdvisorRequests_data on Query {\n  me {\n    id\n  }\n  requests(first: 100) {\n    totalCount\n    edges {\n      node {\n        id\n        ...AdvisorRequestCard_data\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n\nfragment AdvisorRequestCard_data on Request {\n  id\n  text(truncate: 180)\n  fullText: text\n  createdAt(format: \"MMM Do, YYYY\")\n  author {\n    id\n    displayName\n    photoURL\n  }\n  responses {\n    author {\n      id\n    }\n    id\n  }\n}\n\nfragment Toolbar_me on User {\n  id\n  username\n  displayName\n  photoURL\n  isAdvisor\n}\n\nfragment AutoUpdater_user on User {\n  id\n  timeZone\n}\n\nfragment Toolbar_data on Query {\n  unreadMessageCount\n}\n\nfragment Monitor_data on Query {\n  unreadMessageCount\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '74bf1f164211abeaf30135ad464db5a6';
module.exports = node;
