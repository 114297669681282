import React from 'react';
import loading from '../utils/loading';
import LinearProgressBar from '@material-ui/core/LinearProgress';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  colorPrimary: {
    backgroundColor: '#f3e5f5',
    height: '3px !important',
    position: 'absolute',
    width: '100%',
  },
  barColorPrimary: {
    backgroundColor: '#9c27b0',
    height: '3px !important',
  },
}));

function LoadingBar() {
  const [isLoading, setIsLoading] = React.useState(false);
  const s = useStyles();

  React.useEffect(
    () =>
      loading.listen(x => {
        setIsLoading(x);
      }),
    [],
  );

  return (
    isLoading && (
      <LinearProgressBar
        variant="indeterminate"
        classes={{
          colorPrimary: s.colorPrimary,
          barColorPrimary: s.barColorPrimary,
        }}
      />
    )
  );
}

export default LoadingBar;
