import React from 'react';
import MenuIcon from '@material-ui/icons/Menu';
import AppBar from '@material-ui/core/AppBar';
import MUIToolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import withWidth from '@material-ui/core/withWidth';
import { makeStyles } from '@material-ui/styles';

import PrepdupLogo from '../icons/PrepdupLogo';
import Link from '../common/Link';
import LoginLink from '../common/LoginLink';
import Facebook from '../icons/FacebookFooterImg';
import Twitter from '../icons/Twitter';

const useStyles = makeStyles(theme => ({
  topNavNoShadow: {
    boxShadow: 'none',
  },
  topNavShadow: {
    boxShadow: theme.shadows[1],
  },
  customMUIToolbar: {
    minWidth: '84%',
    margin: '0 auto',
    justifyContent: 'space-between',
    [theme.breakpoints.up(1280)]: {
      maxWidth: 1216,
      minWidth: 1216,
    },
  },
  list: {
    width: 250,
  },
  logo: {
    position: 'relative',
    top: 5,
    [theme.breakpoints.down(960)]: {
      top: 6,
    },
    [theme.breakpoints.down(720)]: {
      top: 2,
    },
  },
  prepLogo: {
    marginLeft: '40px !important',
    marginRight: '16px !important',
  },
  linkGrey: {
    color: theme.palette.text.secondary,
    textDecoration: 'none',
    '&:hover': {
      color: theme.palette.grey[700],
      textDecoration: 'underline',
    },
  },
  footer: {
    paddingTop: 7,
    paddingBottom: 7,
    paddingLeft: '7.5%',
    paddingRight: '7.5%',
    height: theme.spacing(6),
    backgroundColor: theme.palette.grey[200],
    minWidth: '100%',
    boxSizing: 'border-box',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  iconContainer: {
    width: 60,
    display: 'flex',
    justifyContent: 'space-between',
  },
  icon: {
    maxWidth: 24,
    maxHeight: 24,
  },
  navigation: {
    display: 'flex',
  },
  linkButton: {
    textTransform: 'none',
    [theme.breakpoints.down(720)]: {
      display: 'none',
    },
  },
  menuButton: {
    [theme.breakpoints.up(721)]: {
      display: 'none',
    },
  },
  loginLink: {
    textDecoration: 'none',
  },
}));

const navigationLinks = [
  { name: 'About', path: '/about' },
  /* { name: 'FAQ', path: '/about' }, */
  { name: 'Log In', path: '/login' },
];

function DrawerView(props) {
  const { toggleDrawer, drawerOpen } = props;
  const s = useStyles();

  return (
    <Drawer
      open={drawerOpen}
      ModalProps={{ keepMounted: true }}
      onClose={toggleDrawer}
      anchor="right"
    >
      <List className={s.list}>
        {navigationLinks.map((x, index) => {
          return x.path === '/login' ? (
            <LoginLink
              key={index + x.name}
              href="/login?return=/"
              className={s.loginLink}
            >
              <ListItem button key={x.name}>
                <ListItemText primary={x.name} />
              </ListItem>
            </LoginLink>
          ) : (
            <ListItem key={x.name} component={Link} href={x.path}>
              <ListItemText primary={x.name} />
            </ListItem>
          );
        })}
      </List>
    </Drawer>
  );
}

function Toolbar(props) {
  const { toggleDrawer, width, shadow, earlyAccess, drawerOpen } = props;
  const s = useStyles();

  return (
    <div>
      <AppBar
        className={shadow ? s.topNavShadow : s.topNavNoShadow}
        color="inherit"
        position="fixed"
      >
        <MUIToolbar className={s.customMUIToolbar} color="black">
          <div className={s.navigation}>
            <div className={s.logo}>
              {earlyAccess ? (
                <PrepdupLogo height={width === 'xs' ? 24 : 28} />
              ) : (
                <Link href="/">
                  <PrepdupLogo height={width === 'xs' ? 24 : 28} />
                </Link>
              )}
            </div>
            {!earlyAccess && (
              <Button
                href="/about"
                className={`${s.linkButton} ${s.prepLogo}`}
                component={Link}
              >
                About
              </Button>
            )}
            {/* !earlyAccess && <Button className={s.linkButton}>FAQ</Button> */}
          </div>
          {!earlyAccess && (
            <React.Fragment>
              <LoginLink href="/login?return=/" className={s.loginLink}>
                <Button className={s.linkButton}>Log In</Button>
              </LoginLink>
            </React.Fragment>
          )}
          {!earlyAccess && (
            <IconButton
              color="inherit"
              area-label="Open drawer"
              onClick={toggleDrawer}
              className={s.menuButton}
            >
              <MenuIcon />
            </IconButton>
          )}
        </MUIToolbar>
      </AppBar>
      <DrawerView
        toggleDrawer={toggleDrawer}
        drawerOpen={drawerOpen}
        classes={s}
      />
    </div>
  );
}

export const ToolbarWithDrawer = withWidth()(Toolbar);

export function Footer(props) {
  const { earlyAccess } = props;
  const s = useStyles();

  return (
    <div className={s.footer}>
      <div>
        {!earlyAccess && (
          <Link className={s.linkGrey} href="/terms">
            <Typography className={s.linkGrey}>Terms & Privacy</Typography>
          </Link>
        )}
      </div>
      <div className={s.iconContainer}>
        <div className={s.icon}>
          <a
            target="_blank"
            href="https://www.facebook.com/iamprepdup/"
            rel="noopener noreferrer"
          >
            <Facebook />
          </a>
        </div>
        <div className={s.icon}>
          <a
            target="_blank"
            href="https://twitter.com/Prepdup"
            rel="noopener noreferrer"
          >
            <Twitter />
          </a>
        </div>
      </div>
    </div>
  );
}
