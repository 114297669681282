/* @flow */

import React from 'react';
import { graphql } from 'relay-runtime';
import Layout from '../common/Layout';

export default [
  {
    path: '/terms',
    query: graphql`
      query termsDataQuery {
        ...Layout_data
      }
    `,
    components: () => [
      import(/* webpackChunkName: 'terms' */ './Content'),
      import(/* webpackChunkName: 'terms' */ './Terms'),
    ],
    render: ([Content, Terms], data, ctx) => ({
      title: `${Terms.title} • Prepdup`,
      component: (
        <Layout data={data}>
          <Content path={ctx.path} component={Terms} />
        </Layout>
      ),
      chunks: ['terms'],
    }),
  },
  {
    path: '/terms/privacy-policy',
    query: graphql`
      query termsPrivacyQuery {
        ...Layout_data
      }
    `,
    components: () => [
      import(/* webpackChunkName: 'terms' */ './Content'),
      import(/* webpackChunkName: 'privacy' */ './Privacy'),
    ],
    render: ([Content, Privacy], data, ctx) => ({
      title: `${Privacy.title} • Prepdup`,
      component: (
        <Layout data={data}>
          <Content path={ctx.path} component={Privacy} />
        </Layout>
      ),
      chunks: ['terms', 'privacy'],
    }),
  },
];
